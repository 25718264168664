import React from "react"
import Img from "gatsby-image"

import { css } from "@emotion/core"

import { MdWork, MdLocationOn, MdSms, MdPlayCircleFilled } from "react-icons/md"

const Production = (props) => {
    const production = props.data
    const title = production.title.text
    const subtitle = production.subtitle.text
    const desc = production.desc
    const place = production.place
    const client = production.client && production.client.document.data
    const image = production.image.fluid
    const quote = production.quotes[0]
    const quote_text = quote.quote.text
    const quote_author = quote.author
    const quote_author_position = quote.author_position
    const links = production.links
    // console.log("Props", production)

    return (
        <div css={card}>
            <div>
                <Img fluid={image} />
                <div className="production__content">
                    {client && <h6>{client.name.text}</h6>}
                    <h4>{title}</h4>
                    <h5><MdWork/> {subtitle}</h5>
                    <p>{desc}</p>
                    {links && links.map( link => {
                        if ( link.link.url ) return (
                            <div key={link.link.url} className="production__link">
                                <a href={link.link.url} target="_blank" rel="nofollow noreferrer noopener"><MdPlayCircleFilled /> {link.button_text || "Voir plus"}</a>
                            </div>
                        )
                        return null 
                    })}
                    {place && <div css={placeTag}><MdLocationOn /> {place}</div>}
                </div>
            </div>

            <div className="production__footer">
                {quote_text && 
                    <div css={citation}>
                        <div>{quote_text}</div>
                        {quote_author && <div className="quote-author"><MdSms /> <i><b>{quote_author}</b> {quote_author_position}</i></div>}
                    </div>
                }
            </div>
        </div>
    )
}

export default Production

const card = css`
    border: 1px solid #F7F7F7;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, .11);
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .gatsby-image-wrapper {
        max-height: 230px;
    }

    .production__content {
        padding: 20px 20px 0;

        h5, h6 {
            font-family: 'Open Sans', sans-serif;
        }
    
        h6 {
            text-transform: uppercase;
            font-size: 11px;
            margin: 0;
            opacity: .5;
            font-weight: 700;
        }
    
        h4 {
            margin: 6px 0 10px;
        }
    
        h5 {
            display: inline-block;
            font-size: 12px;
            margin: 0 0 10px;
            background-color: #F7F7F7;
            padding: 8px 14px;
            border-radius: 5px;
            opacity: .7;
    
            svg {
                display: inline-block;
                margin-bottom: -2px;
                margin-right: 10px;
            }
        }
    
        p {
            font-size: 13px;
            margin-bottom: 10px;
        }

        .production__link {
            font-size: 14px;
            padding: 8px 0 11px;
            border-top: 1px solid #F7F7F7;
            
            a {
                text-decoration: none;
                color: #0097ff;

                svg {
                    margin-bottom: -2px;
                }
            }
        }
    }
`

const placeTag = css`
    position: absolute;
    top: 10px;
    left: 10px;
    padding: 5px 10px;
    background-color: rgba(0, 0, 0, .5);
    color: white;
    border-radius: 5px;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 700;

    svg {
        margin-bottom: -2px;
    }
`

const citation = css`
    background-color: #0097ff;
    // background-color: black;
    color: white;
    font-size: 14px;
    padding: 20px;
    border-top: 1px solid #F7F7F7;
    min-height: 130px;

    .quote-author {
        opacity: .66;
        margin-top: 6px;
    }

    svg {
        margin-bottom: -2px;
    }
`