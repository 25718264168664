import React from "react"
import Img from "gatsby-image"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { css } from "@emotion/core"
import Product from "../components/product"
import Package from "../components/package"
import Form from "../components/form"
import Production from "../components/production"

const IndexPage = (props) => {
  // console.log(props)
  const products = props.data.allProductsYaml.edges
  const packages = props.data.packages.edges
  const productions = props.data.productions.edges

  return (
    <Layout>
      <SEO title="Live Video en Suisse romande" />
      <div css={hero}>
        <Img fluid={props.data.hero.childImageSharp.fluid} />
        
        <div css={heroInner}>
          <div className="container">
            <h1>Vidéo live streaming pour le web en Suisse romande <span role="img" aria-label="camera" style={{display: 'inline-block', position: 'relative', bottom: '-10px'}}>🇨🇭</span></h1>
            <p>Diffusez votre événement, concert, conférence, meeting, webinar, webcast... en live vidéo grâce à nos services de live streaming pour le web en Suisse romande.</p>
          </div>
        </div>
      </div>

      <div className="container">
        <div css={aboutUs}>
          <h2>Qui sommes nous ?</h2>
          <p>Live-video.ch est un service spécialisé dans la création de <b>live streaming pour le web et les réseaux sociaux des PMEs en Suisse romande</b>.</p> 
          <p>Notre expertise en production multimédia depuis plus d'une dizaine d'années ainsi que notre implication dans le marketing et communication digitale et les réseaux sociaux nous a amené au fil du temps à proposer des services de live streaming web de plus en plus poussés, avec des formats adaptés aux différents budgets des PMEs de la région.</p>
          <p>La croissance de la demande de live streaming en suisse romande en partie due à la "crise" inédite (COVID-19) nous a poussé à développer cette offre de vidéo live pour fournir aux PMEs romandes des solutions adaptées aux besoins de digitalisation : webinars, Microsoft team meetings, Zoom, diffusion sur YouTube Live...</p>
        </div>
        <div>
          <h2><span role="img" aria-label="camera">📹</span> Nos prestations de Live Streaming en Suisse</h2>
          {products.map( ({ node: product }) => (
            <Product product={product} key={product.id} />
            ))}
        </div>
      </div>
        
  
      <div css={packagesPossibilities}>
        <div className="container">
            <h2>Différents packages live possibles</h2>
            <p className="intro">Pour s'adapter à différents budgets et besoins, nous proposons différents formats et outils afin de réaliser ces lives, quelle que soit la plateforme finale de diffusion. Ces "setups" correspondent à des complexités de mise en place et réalisation différentes, et à des fonctionnalités, rendus et qualités à choisir avec vous.</p>
            <div className="three-cols">
              {packages.map( ({node: pack}) => (
                <Package 
                  key={pack.id}
                  title={pack.title}
                  desc={pack.description}
                  img={pack.img}
                  cams={pack.cams}
                  price={pack.price}
                  movement={pack.movement}
                />
              ))}
            </div>
        </div>
      </div>


      <div className="container">
        <Form />
      </div>

      <div style={{paddingTop: "100px", paddingBottom: "50px"}}>
        <div className="container">
          <h2>Exemples de prestations Live Vidéo</h2>
          <p>Quelques exemples de réalisations, n'hésitez pas à nous demander des prestations spécifiques, certains de nos mandats n'étant pas publiés ici pour des questions de confidentialité.</p>
          <div className="two-cols">
            {productions.map( ({node: production}) => (
              <Production 
                key={production.uid}
                {...production}
              />
            ))}
          </div>
        </div>
      </div>

      <div css={textContent}>
        <div className="container">
          <section style={{marginTop: "50px"}}>
            <h2>Votre partenaire pour la diffusion de vidéos live en romandie</h2>
            <p>Le live streaming vous permet de <b>diffuser un contenu vidéo en direct auprès de vos audiences</b>, que ce soit en public (vidéo accessible à tous) ou en privé (pour vos collaborateurs sur votre intranet, ou par lien privé par exemple).</p>
            <p>Nous pouvons retransmettre votre live stream vidéo <b>sur une ou plusieurs plateformes ou réseaux sociaux</b> en fonction de vos objectifs de communication : Facebook live et Youtube par exemple ! Il est aussi possible de réaliser des <b>livestreams directement sur votre site internet</b> si vous souhaitez éviter les réseaux sociaux. Enfin, nous pouvons diffuser en direct <b>sur des plateformes de communication interne comme Skype, Microsoft teams, Zoom</b>...</p>
            <p>L'intérêt de travailler ensemble vient du fait que nous nous chargeons du concept live et du setup technique. <b>Nous vous conseillons et vous accompagnons sur le meilleur format de media-stream</b>, le run of show (déroulé du live), mais aussi le nombre de caméras, le type de caméras, leur placement / mobilité. Nous nous assurons que les conditions techniques pour permettre le streaming sont bonnes sur le lieux de l'événement, grâce à des tests en amont et en collaboration avec votre équipe ou département IT.</p>
          </section>

          <section style={{marginTop: "50px"}}>
              <h2>Différents formats de live stream vidéo en Suisse</h2>
              <p>Votre live stream peut avoir différents formats en fonction de vos objectifs et de votre budget. Un live vidéo à une caméra peut être un premier moyen d'offrir un contenu en direct avec une meilleure qualité qu'une simple webcam, tout en intégrant une sonorisation professionnelle pour un résultat bien au dessus d'une solution Do It Yourself. La caméra peut être mobile, afin de se déplacer et d'offrir une expérience en direct immersive. La caméra peut aussi disposer d'un zoom, afin de montrer plus de détails ou de contexte (dézoom) dans votre live vidéo.</p>
              <p>L'<b>ajout d'une seconde caméra va permettre de commencer à créer un show</b>, avec deux points de vue, "montés" (édités) en direct afin de choisir les meilleurs angles pour vos viewers. Avec deux caméras, il va être possible de varier les angles, les zooms, tout en changeant de valeurs de plans pendant que l'autre caméra est en direct. le son est harmonisé entre les deux sources par le monteur.ice afin d'obtenir un résultat naturel et professionnel.</p>
              <p>Il est ensuite possible d'<b>ajouter plus de sources à votre vidéo live streaming</b>, que ce soit des caméras pour d'avantages d'angles, de mobilité (caméra mobile sur stabilisateur) ou d'optiques (objectifs, zoom, gopro...). Il est aussi possible d'ajouter d'autres angles (autre feeds live), des slides (présentation power point), des vidéos à intégrer. Le montage peut se faire en passant d'un plan à l'autre, ou bien intégrer/insérer l'image dans l'image (picture in picture mode)</p>
          </section>
        </div>
      </div>

    </Layout>
  )
}

export default IndexPage

const hero = css`
  margin: -70px 0 50px;
  background-color: #f9f9f9;
  overflow: hidden;
  position: relative;
  max-height: 600px;
  z-index: -50;
`

const heroInner = css`
  position: absolute;
  bottom: 40px;
  width: 100%;
  color: white;

  h1 {
    font-size: 24px;
  }

  p {
    margin: 0;
    font-size: 14px;
    line-height: 1.2;
  }

  @media screen and (min-width: 800px) {
    h1 {
      font-size: 80px;
    }

    p {
      line-height: 1.4;
      font-size: 24px;
    }
  }
`

const aboutUs = css`
  padding: 20px 20px 0;
  border: 1px solid #F7F7F7;
  background-color: #F7F7F7;
  border-radius: 20px;
  margin-bottom: 50px;
`

const textContent = css`
  background-color: #f7f7f7;
  padding-top: 40px;
  padding-bottom: 40px;
  margin-top: 80px;
`

const packagesPossibilities = css`
  margin-top: 100px;
  background-color: #0097ff;
  padding-top: 50px; 
  padding-bottom: 50px;
  
  h2 {
    color: white;
  }

  p.intro {
    color: white;
  }
`

export const indexQuery = graphql`{
  allProductsYaml {
    edges {
      node {
        id
        desc
        name
        logo {
          childImageSharp {
            fluid {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    }
  }
  hero: file(relativePath: { eq: "hero-dark.jpg" }) {
    childImageSharp {
      fluid {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
      }
    }
  }
  packages: allPackagesYaml(sort: {fields: number, order: ASC}) {
    edges {
      node {
        number
        id
        title
        description
        img {
          childImageSharp {
            fluid {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
        cams
        price
        movement
      }
    }
  }
  productions: allPrismicProductions(filter: {tags: {eq: "Live"}}) {
    edges {
      node {
        uid
        data {
          client {
            document {
              ... on PrismicClients {
                id
                data {
                  name {
                    text
                  }
                  place
                }
              }
            }
          }
          place
          quotes {
            quote {
              text
            }
            author
            author_position
          }
          desc
          subtitle {
            text
          }
          title {
            text
          }
          video {
            url
            embed_url
            html
          }
          links {
            button_text
            link {
              url
            }
          }
          image {
            fluid(imgixParams: {q: 100}, maxWidth: 1000) {
              ...GatsbyPrismicImageFluid
            }
          }
        }
      }
    }
  }
}`
