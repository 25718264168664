import React from 'react'
import Img from "gatsby-image"
// import { Link } from "gatsby"

import { css } from "@emotion/core"

import { MdPhotoCamera, MdShoppingCart, MdWifi } from "react-icons/md"


const Package = (props) => {
    const {
        title, 
        desc,
        img,
        cams,
        price,
        movement,
    } = props
    // console.log(props)

    return (
        <div css={card}>
            <div>
                {img &&
                    <div>
                        <Img fluid={img.childImageSharp.fluid} />
                    </div>
                }
                <div css={main}>
                    <h3>{title}</h3>
                    <p>{desc}</p>
                </div>
            </div>
            <div css={footer}>
                <div><MdPhotoCamera /> {cams}</div>
                <div><MdShoppingCart /> A partir de {price}</div>
                <div><MdWifi /> {movement}</div>
            </div>
        </div>
    )
}

export default Package

const card = css`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: white;
    margin: 20px 0;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, .11);
    padding-bottom: 5px;
    overflow: hidden;
`

const main = css`
    margin: 20px;

    h3 {
        margin-bottom: 10px;
    }

    p { 
        margin: 0;
        font-size: 16px;
    }
`

const footer = css`
    font-size: 14px;

    > div {
        padding: 7px 20px;
        border-top: 1px solid #F7F7F7;

        svg {
            margin-bottom: -2px;
            margin-right: 10px;
        }
    }
`