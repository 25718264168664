import React from 'react'
import Img from "gatsby-image"
import { Link } from "gatsby"

import { css } from "@emotion/core"

const Product = (props) => {
    const {
        id, 
        name, 
        desc,
        logo,
    } = props.product

    // console.log(props)

    return (
        <div css={card}>
            {logo &&
                <div css={productImg}>
                    <Img fluid={logo.childImageSharp.fluid} />
                </div>
            }
            <div css={main}>
                <h3>{name}</h3>
                <p>{desc}</p>
                
                <div css={link}>
                    <Link to={`/${id}`}>Voir {name}</Link>
                </div>
            </div>
        </div>
    )
}

export default Product

const card = css`
    display: block;
    margin: 20px 0;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, .11);
    padding-bottom: 10px;

    @media screen and (min-width: 700px) {
        display: flex;
        justify-content: flex-start;
        padding: 0;
        align-items: center;
        overflow: hidden;
    }
`

const main = css`
    margin: 0 20px 20px;
    max-width: 700px;

    @media screen and (min-width: 700px) {
        margin: 20px;
    }

    h3 {
        margin-bottom: 10px;
    }

    p { 
        margin: 0;
        font-size: 16px;
    }
`

const productImg = css`
    width: 70px;
    border-radius: 10px;
    overflow: hidden;
    margin-left: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, .11);
    position: relative;
    top: -10px;

    @media screen and (min-width: 700px) {
        width: 200px;
        border-radius: 0;
        margin: 0;
        box-shadow: none;
        top: 0;
    }
`

const link = css`
    font-size: 14px;
    margin-top: 20px;
    margin-bottom: 0;

    a {
        text-decoration: none;
        color: grey;
        padding: 10px 20px;
        background-color: #F9F9F9;
        border-radius: 50px;
        transition: color .5s ease-in-out;

        :hover {
            color: red;
        }
    }
`